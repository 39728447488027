<template>
  <v-img
    class="d-none d-sm-flex logo"
    src="@/assets/logos/vector-logo.png"
    contain
  ></v-img>
</template>

<script>
export default {};
</script>

<style scoped>
.logo {
  position: absolute;
  top: 0;
  right: 50px;
  width: 100px;
}
</style>
