export const mainTabs=[
    {
        title:'الصفحة الرئيسية',
        icon:'mdi-home-outline',
        link:'/',
    },
    {
        title:'إدارة فرص العمل',
        icon:'mdi-briefcase-variant-outline',
        link:'/job-opportunities',
    },
];
