<template>
  <div>
    <!--circular background-->
    <div class="circle circle-1 d-none d-md-flex"></div>
    <div class="circle circle-2"></div>
    <div class="circle circle-3"></div>

    <v-container
      class="lighterSecondary"
      style="min-height: 100vh"
      fill-height
      fluid
    >
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="5">
          <h2 class="secondary--text text-center my-5">منصة البركة | ناشر فرص العمل </h2>
          <LoginCard />
        </v-col>
        <v-col cols="5" class="d-none d-md-inline">
          <LoginLogo />
          <LoginIllustration />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import LoginLogo from "../components/login/LoginLogo.vue";
import LoginIllustration from "../components/login/LoginIllustration.vue";
import LoginCard from "../components/login/LoginCard.vue";

export default {
  components: { LoginLogo, LoginIllustration, LoginCard },
};
</script>

<style scoped>
.circle {
  position: fixed;
  left: -200px;
  top: -200px;
  border-radius: 50%;
  background-color: #a87c4f;
  z-index: 0;
  opacity: 0.2;
}

.circle-1 {
  width: 1000px;
  height: 1000px;
  animation: circle-animation;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.circle-2 {
  width: 750px;
  height: 750px;
  animation: circle-animation;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
}

.circle-3 {
  width: 500px;
  height: 500px;
  animation: circle-animation;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

@keyframes circle-animation {
  0% {
    top: -1346px;
    left: -1075px;
  }

  100% {
    left: -200px;
    top: -200px;
  }
}
</style>
