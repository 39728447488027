<template>
  <v-container>
    <v-text-field
      v-if="isEditable"
      v-on="$listeners"
      v-bind="$attrs"
      outlined
      persistent-placeholder
      :label="label"
      :placeholder="placeholder"
      :prepend-inner-icon="icon"
    ></v-text-field>
    <v-col v-else>
      <v-row align="center">
        <v-icon class="ml-4" color="primary" size="40">{{ icon }}</v-icon>
        <h3 class="primary--text">{{ label }}</h3>
      </v-row>
      <v-row>
        <span class="secondary--text mt-2">{{ model }}</span>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import { mapState } from "pinia";
import { useProfileStore } from "../store/ProfileStore";

export default {
  computed: {
    ...mapState(useProfileStore, ["isEditable"]),
  },
  props: {
    icon: String,
    model: String,
    label: String,
    placeholder: String,
    required: Boolean,
  },
};
</script>

<style></style>
