<template>
  <div>
    <v-btn to="/job-opportunities/create" color="primary"
      ><v-icon>mdi-plus</v-icon> إنشاء فرصة عمل</v-btn
    >
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
