<template>
  <v-card class="light py-10 rounded-xl">
    <h1 class="primary--text text-center">إعادة تعيين كلمة السر</h1>
    <v-container class="mt-5">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step
            color="accent"
            step="1"
            id="email"
            :complete="step > 1"
          >
            إدخال البريد الإلكتروني
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step color="accent" step="2" id="confirmation">
            تأكيد البريد وإعادة التعيين
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <EmailStep @completed="postSendVerificationCodeForReset" />
          </v-stepper-content>
          <v-stepper-content step="2">
            <ResetPasswordStep @completed="postResetPassword" />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "pinia";
import { useAuthStore } from "../../store/AuthStore";

import EmailStep from "./steps/EmailStep.vue";
import ResetPasswordStep from "./steps/ResetPasswordStep.vue";

export default {
  components: {
    EmailStep,
    ResetPasswordStep,
  },
  data() {
    return {
      step: 1,
      email: "",
    };
  },
  methods: {
    ...mapActions(useAuthStore, [
      "sendVerificationCodeForReset",
      "resetPassword",
    ]),
    goNextStep() {
      this.step++;
    },
    postSendVerificationCodeForReset(email) {
      var data = { email: email };
      this.email = email;
      this.sendVerificationCodeForReset(data)
        .then(() => this.goNextStep())
        .catch((err) => {
          err;
        });
    },
    postResetPassword(data) {
      data.email = this.email;
      this.resetPassword(data)
        .then(() => this.$router.push("/login"))
        .catch((err) => {
          err;
        });
    },
  },
};
</script>

<style></style>
