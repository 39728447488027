<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedApplicants"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:actions="{ item }">
      <RatingButton :item="item" />
    </template>
    <template v-slot:[`item.isOrganizationGraduate`]="{ item }">
      <v-icon v-if="item.isOrganizationGraduate == 1">mdi-check-outline</v-icon>
      <v-icon v-else>mdi-close</v-icon>
    </template>
    <template v-slot:[`item.jobResume`]="{ item }">
      <a :href="item.jobResume" target="_blank" download>
        <v-icon>mdi-file</v-icon>
      </a>
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useJobApplicantsStore } from "../store/JobApplicantsStore";

import RatingButton from "./RatingButton.vue";

export default {
  components: { RatingButton },
  computed: {
    ...mapState(useJobApplicantsStore, ["paginatedApplicants"]),
    ...mapWritableState(useJobApplicantsStore, ["searchKey"]),
  },
  data() {
    return {
      headers: [
        { text: "الرقم", align: "start", value: "id" },
        { text: "الاسم", value: "applicant.name" },
        { text: "تاريخ التقدم", value: "createdAt" }, 
        { text: "البريد الالكتروني", value: "applicant.email" },
        { text: "رقم الهاتف", value: "applicant.phoneNumber" },
        { text: "خريج الجمعية", value: "isOrganizationGraduate" },
        { text: "السيرة الذاتية", sortable: false, value: "jobResume" },
        { text: "تقييم", sortable: false, value: "actions" },
      ],
    };
  },
  methods: {
    ...mapActions(useJobApplicantsStore, [
      "fetchJobApplicants",
      "setSearchNFetch",
      "setTableOptionsNFetch",
      "setJobId",
    ]),
  },
  mounted() {
    this.setJobId(this.$route.params.id);
    this.fetchJobApplicants();
  },
  destroyed() {
    this.searchKey = null;
  },
};
</script>
