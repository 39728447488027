<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedJobs"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:actions="{ item }">
      <ShowJobDetailsButton :item="item" />
      <RenewJobButton :item="item" />
      <ShowJobApplicantsButton :item="item" />
      <CloseJobButton :item="item" />
      <EditJobButton :item="item" />
    </template>
    <template v-slot:[`item.status`]="{ item }">
      {{ translateStatus(item.status) }}
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useJobOpportunitiesStore } from "../store/JobOpportunitiesStore";
import JobOpportunity from "../models/JobOpportunity";
import ShowJobDetailsButton from "./action_buttons/ShowJobDetailsButton.vue";
import RenewJobButton from "./action_buttons/RenewJobButton.vue";
import CloseJobButton from "./action_buttons/CloseJobButton.vue";
import ShowJobApplicantsButton from "./action_buttons/ShowJobApplicantsButton.vue";
import EditJobButton from "./action_buttons/EditJobButton.vue";

export default {
  computed: {
    ...mapState(useJobOpportunitiesStore, ["paginatedJobs"]),
    ...mapWritableState(useJobOpportunitiesStore,["searchKey"]),
  },
  data() {
    return {
      headers: [
        { text: "الرقم", align: "start", value: "id" },
        { text: "المسمى الوظيفي", value: "title" },
        { text: "تاريخ الإضافة", value: "createdAt" },
        { text: "الحالة", value: "status" },
        { text: "عدد المتقدمين", value: "applicantsCount" },
        { text: "الإجراءات", sortable: false, value: "actions" },
      ],
    };
  },
  mounted() {
    this.fetchJobs();
  },
  methods: {
    ...mapActions(useJobOpportunitiesStore, [
      "fetchJobs",
      "setTableOptionsNFetch",
      "setSearchNFetch",
    ]),
    translateStatus(prop) {
      return JobOpportunity.trans(prop);
    },
  },
  components: {
    ShowJobDetailsButton,
    RenewJobButton,
    ShowJobApplicantsButton,
    EditJobButton,
    CloseJobButton,
  },
  destroyed(){
    this.searchKey=null;
  },
};
</script>
