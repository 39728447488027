<template>
  <v-img class="logo" src="@/assets/logos/vector-logo.png" contain></v-img>
</template>

<script>
export default {};
</script>

<style scoped>
.logo {
  position: absolute;
  top: 0;
  left: 50px;
  width: 100px;
}
</style>
