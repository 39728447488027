import Client from "@/core/classes/client/Client";
import BaseService from "@/core/classes/base/BaseService";
import { getStatisticsAPI } from "./Home.api";

export default class HomeService extends BaseService {
  static async getStatistics() {
    const res = await Client.get(getStatisticsAPI);
    return await Promise.resolve(res);
  }
}
