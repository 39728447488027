<template>
  <BaseDialog :showCondition="show" @closed="closeDialog()">
    <template v-slot:title>
      <p class="primary--text">تسجيل الخروج</p>
    </template>
    <template v-slot:body>
      <p class="text-center mb-8">هل أنت متأكد من أنك تريد تسجيل الخروج؟</p>
    </template>
    <template v-slot:actions>
      <v-row justify="space-around">
        <v-btn color="accent" rounded @click="postLogout()">نعم</v-btn>
        <v-btn color="light" rounded @click="closeDialog()">لا</v-btn>
      </v-row>
    </template>
  </BaseDialog>
</template>

<script>
import { mapActions } from "pinia";
import { useAuthStore } from "@/modules/auth/store/AuthStore";

export default {
  props: {
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },

  data() {
    return {
      show: this.showCondition,
    };
  },
  methods: {
    closeDialog() {
      this.show = false;
      this.$emit("closed");
    },
    ...mapActions(useAuthStore, ["logout"]),
    postLogout() {
      this.logout()
        .then(() => {
          this.closeDialog();
          this.$router.push("/login");
        })
        .catch(() => {});
    },
  },
};
</script>
