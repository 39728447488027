<template>
  <div class="mr-sm-10 mt-6">
    <v-col v-if="isEditable">
      <v-text-field
        v-model="profile.name"
        :rules="[requiredRule]"
        outlined
        persistent-placeholder
        label="اسم الشركة"
        placeholder="اسم الشركة"
        prepend-inner-icon="mdi-account"
      >
      </v-text-field>
      <v-checkbox
        label="بيانات الشركة ظاهرة ضمن الإعلان"
        :input-value="this.profile.isUserVisible == 1"
        @change="changeProfileVisibility($event)"
      ></v-checkbox>
    </v-col>
    <h1 v-else class="secondary--text text-center">{{ profile.name }}</h1>
  </div>
</template>

<script>
import { mapState, mapWritableState } from "pinia";
import { useProfileStore } from "../store/ProfileStore";

import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";

export default {
  mixins: [FormValidationRulesMixin],
  computed: {
    ...mapState(useProfileStore, ["isEditable"]),
    ...mapWritableState(useProfileStore, ["profile"]),
  },
  methods: {
    changeProfileVisibility(value) {
      if (value) {
        this.profile.isUserVisible = 1;
      } else {
        this.profile.isUserVisible = 0;
      }
    },
  },
};
</script>

<style></style>
