import { getJobApplicantsAPI, sendApplicantRateAPI } from "./JobApplicants.api";
import BaseService from "@/core/classes/base/BaseService";
import Client from "@/core/classes/client/Client";
import Mapper from "@/core/classes/mapper/Mapper";

export default class JobApplicantsService extends BaseService {
  static async getPaginatedJobApplicants(id, params) {
    const res = await Client.get(getJobApplicantsAPI(id), params);
    return await Promise.resolve(res);
  }
  static async sendApplicantRate(resumeId, data) {
    const res = await Client.post(
      sendApplicantRateAPI(resumeId),
      Mapper.camelToUnderscore(data)
    );
    return await Promise.resolve(res);
  }
}
