<template>
  <BaseJobForm
    :jobObject="job"
    formActionName="إنشاء الفرصة"
    @jobSubmitted="(submittedJob) => sendJobData(submittedJob)"
  />
</template>

<script>
import { mapActions } from "pinia";
import JobOpportunity from "../../models/JobOpportunity";
import { useJobOpportunityStore } from "../../store/JobOpportunityStore";
import BaseJobForm from "./BaseJobForm.vue";

export default {
  data() {
    return {
      job: new JobOpportunity(),
    };
  },
  methods: {
    ...mapActions(useJobOpportunityStore, ["sendJobCreationRequest"]),
    sendJobData(job) {
      this.sendJobCreationRequest(job)
        .then(() => {
          this.$router.push("/job-opportunities");
        })
        .catch();
    },
  },
  components: { BaseJobForm },
};
</script>

<style scoped></style>
