<template>
  <v-navigation-drawer
    color="lighterSecondary"
    overlay-color="lightNeutral"
    v-model="isDrawerVisible"
    height="100%"
    app
    :right="true"
  >
    <!--drawer background-->
    <template v-slot:img>
      <v-img
        class="opacity-md"
        max-width="140"
        src="@/assets/decorations/side-drawer-cut.svg"
      >
      </v-img>
    </template>
    <!--drawer header-->
    <template v-slot:prepend>
      <v-list-item height="100" class="my-12">
        <v-avatar class="elevation-5" color="white" size="70">
          <v-img v-if="logoIsSet" contain :src="logo" class="rounded"></v-img>
          <v-icon v-else x-large color="lightSecondary">mdi-domain</v-icon>
        </v-avatar>
        <v-list-item-content>
          <div class="mr-3 pl-3 secondary--text f-s-lg text-center">
            <p class="break-word">{{ name ? name : "اسم الشركة" }}</p>
          </div>
        </v-list-item-content>
      </v-list-item>
    </template>
    <!--drawer tabs-->
    <v-tabs background-color="transparent" vertical optional>
      <v-tab
        class="f-s-lg justify-start"
        v-for="tab in tabList"
        :key="tab.title"
        :to="tab.link"
      >
        <v-icon class="ml-5"> {{ tab.icon }} </v-icon>
        {{ tab.title }}
      </v-tab>
    </v-tabs>
    <!--profile/logout tabs-->
    <template v-slot:append>
      <v-tabs class="mb-5" background-color="transparent" vertical optional>
        <v-tab class="f-s-lg justify-start" to="/profile">
          <v-icon class="ml-5"> mdi-account-circle </v-icon>
          الملف الشخصي
        </v-tab>
        <v-tab @click="openLogoutDialog()" class="f-s-lg justify-start">
          <v-icon class="ml-5"> mdi-logout </v-icon>
          تسجيل الخروج
        </v-tab>
      </v-tabs>
    </template>
    <LogoutDialog
      :showCondition="showLogoutDialog"
      @closed="closeLogoutDialog()"
    />
  </v-navigation-drawer>
</template>

<script>
import { useAuthStore } from "@/modules/auth/store/AuthStore";
import { useGlobalStore } from "@/store/GlobalStore";
import { mapWritableState, mapState } from "pinia";
import { mainTabs } from "./TabsList";
import LogoutDialog from "../dialogs/LogoutDialog.vue";

export default {
  components: { LogoutDialog },
  data() {
    return {
      tabList: mainTabs,
      showLogoutDialog: false,
    };
  },
  computed: {
    ...mapWritableState(useGlobalStore, ["isDrawerVisible"]),
    ...mapState(useAuthStore, ["logo", "name"]),
    logoIsSet() {
      return this.logo && this.logo != "null" && this.logo != "undefined";
    },
  },
  methods: {
    openLogoutDialog() {
      this.showLogoutDialog = true;
    },
    closeLogoutDialog() {
      this.showLogoutDialog = false;
    },
  },
};
</script>

<style scoped>
.break-word {
  word-break: break-word;
}
.opacity-md {
  opacity: 0.5;
}
.f-s-lg {
  font-size: large;
}
.justify-start {
  justify-content: start;
}
</style>
