<template>
  <v-row class="mb-5" justify="center">
    <HomeStatisticCard title="عدد فرص العمل المنشورة" :value="statistics.job_published_count" unit="فرصة" />
    <HomeStatisticCard title="عدد فرص العمل المقبولة والمجددة" :value="statistics.job_accepted_count" unit="فرصة" />
    <HomeStatisticCard title="عدد المتقدمين على فرص العمل" :value="statistics.applicants_count" unit="متقدم" />
  </v-row>
</template>

<script>
import HomeStatisticCard from "./HomeStatisticCard.vue";
import { mapActions, mapState } from "pinia";
import { useHomeStore } from "../store/HomeStore.js";
export default {
  components: { HomeStatisticCard },
  computed: {
    ...mapState(useHomeStore, ["statistics"]),
  },
  methods: {
    ...mapActions(useHomeStore, ["getStatistics"]),
  },
  mounted() {
    this.getStatistics();
  },
};
</script>

<style></style>
