//this object lists the JobOpportunity object keys and returns the job keys according to server side
export default {
  id: "id",
  title: "title",
  field: "field",
  fieldsIds:"fields_ids",
  workLocation: "work_location",
  requiredExperience: "required_experience_level",
  minSalary: "salary_min",
  maxSalary: "salary_max",
  shiftType: "shift_type",
  workLocationType: "work_location_type",
  requiredSkills: "skills_required",
  workHours: "work_hours",
  minAge: "age_min",
  maxAge: "age_max",
  requiredEducation: "certificate",
  requiredEducationLevel: "certificate_required_level",
  requiredMilitaryServiceStatus: "military_service_termination",
  requiredGender: "gender",
  isSalaryShown: "is_salary_showed",
  extraRequirements: "extra_requirements",
  responsibilities: "responsibilities",
  createdAt: "created_at",
  applicantsCount: "applications_count",
};
