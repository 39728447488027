export default class Profile {
  id;
  name;
  phoneNumber;
  location;
  email;
  about;
  field;
  logo;
  isUserVisible;
  logoChanged = 0;
  constructor(data) {
    this.id = data?.id;
    this.name = data?.name;
    this.location = data?.location;
    this.email = data?.email;
    this.about = data?.about;
    this.field = data?.field;
    this.phoneNumber = data?.phone_number;
    this.isUserVisible = data?.is_user_visible;
    this.logo =
      data?.logo == null
        ? data?.logo
        : process.env.VUE_APP_BASE_URL + data?.logo;
  }
}
