<template>
  <BaseDialog :showCondition="show" @closed="closeDialog()">
    <template v-slot:title>
      <p class="primary--text">الشروط والأحكام</p>
    </template>
    <template v-slot:body>
      <p>
        السادة الراغبين بإعلان الشواغر الوظيفية في مؤسساتكم
        على المنصات التي تضم خريجي جمعية البركة في برامجها التدريبية.. نشكر لكم
        ثقتكم.. <br />
        تحرص جمعية البركة على رفد سوق العمل بكفاءات تجمع بين الاحترافية
        المهنية والاخلاقية عن طريق تقديم تدريب يركز على تمليك هذه المهارات
        المهنية والأخلاقية، ومع أن نتائج الخريجين في العمل مشجعة جدا بشكل عام في
        سوق العمل فإنه لا يمكن إغفال الفروق الفردية بين الخريجين لذلك فمن
        الضروري أن تقوم الجهة الطالبة للموظفين بجميع المراحل التي تضمن مناسبة
        المتقدم للعمل من مقابلات واختبارات حيث يقتصر دور الجمعية على التشبيك بين
        الجهة التي تطلب موظفين مع الخريجين<br />
      </p>
    </template>
    <template v-slot:actions>
      <v-row class="my-3" justify="space-around">
        <v-btn color="accent px-5" rounded @click="register()"> موافق </v-btn>
        <v-btn color="light  px-5" rounded @click="closeDialog()">
          غير موافق
        </v-btn>
      </v-row>
    </template>
  </BaseDialog>
</template>

<script>
import { mapActions } from "pinia";
import { useAuthStore } from "../../store/AuthStore";

export default {
  props: {
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  data() {
    return {
      show: this.showCondition,
    };
  },
  methods: {
    ...mapActions(useAuthStore, ["postRegister"]),
    closeDialog() {
      this.show = false;
      this.$emit("closed");
    },
    register() {
      this.closeDialog();
      this.postRegister()
        .then(() => this.$router.push("/verify-email"))
        .catch(() => {});
    },
  },
};
</script>

<style></style>
