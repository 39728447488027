import Applicant from "./Applicant";

export default class JobApplicant {
  id;
  status;
  rate;
  rateContent;
  isOrganizationGraduate;
  resumeId;
  jobResume;
  applicant;
  createdAt;
  constructor(data) {
    this.id = data?.id;
    this.status = data?.status;
    this.rate = data?.rate;
    this.resumeId = data?.resume_id;
    this.rateContent = data?.rate_content;
    this.createdAt = data?.created_at;
    this.isOrganizationGraduate = data?.is_organization_graduate;
    this.jobResume = process.env.VUE_APP_BASE_URL + data?.job_resume;
    this.applicant = new Applicant(data?.user);
  }
}
