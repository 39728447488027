import UserLayout from "@/core/layouts/UserLayout.vue";
import ResetPasswordPage from "../pages/ResetPasswordPage.vue"

export default {
  path: "/reset-password",
  component: UserLayout,
  children: [
    {
      path: "/reset-password",
      name: "reset_password",
      component: ResetPasswordPage,
      meta: { title: "إعادة تعيين كلمة السر" },
    },
  ],
};
