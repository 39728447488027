<template>
  <BaseCRUDPageLayout :links="links">
    <v-container>
      <v-row v-if="!fetchingData" align="start">
        <v-col cols="12" sm="10" order="2" order-sm="1"
          ><JobDetails :job="job"
        /></v-col>
        <v-col align="start" cols="12" sm="2" order="1" order-sm="2">
          <RenewJobButton
            :item="job"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
          <ShowJobApplicantsButton
            :item="job"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
          <CloseJobButton
            :item="job"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
          <EditJobButton
            :item="job"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import JobDetails from "../components/JobDetails.vue";
import RenewJobButton from "../components/action_buttons/RenewJobButton.vue";
import CloseJobButton from "../components/action_buttons/CloseJobButton.vue";
import ShowJobApplicantsButton from "../components/action_buttons/ShowJobApplicantsButton.vue";
import EditJobButton from "../components/action_buttons/EditJobButton.vue";
import { mapActions, mapState } from "pinia";
import { useJobOpportunityStore } from "../store/JobOpportunityStore";

export default {
  mounted() {
    var jobId = this.$route.params.id;
    this.fetchJob(jobId)
      .then(()=>{
        this.fetchingData=false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useJobOpportunityStore, ["job"]),
  },
  methods: {
    ...mapActions(useJobOpportunityStore, ["fetchJob"]),
  },
  data() {
    return {
      fetchingData:true,
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "إدارة فرص العمل",
          href: "/job-opportunities",
          disabled: false,
        },
        {
          text: "فرصة العمل رقم " + this.$route.params.id,
          disabled: true,
        },
      ],
    };
  },
  components: {
    JobDetails,
    RenewJobButton,
    ShowJobApplicantsButton,
    EditJobButton,
    CloseJobButton,
  },
};
</script>
