import UserLayout from "@/core/layouts/UserLayout.vue";
import LoginPage from "../pages/LoginPage.vue";

export default {
  path: "/login",
  component: UserLayout,
  children: [
    {
      path: "/login",
      name: "login",
      component: LoginPage,
      meta: { title: "تسجيل الدخول" },
    },
  ],
};
