import NotFound from "@/core/components/NotFound.vue";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

/**
 * @desc this function is pull all routes from modules , the .routes extension is required required in route files
 * */
const routesFiles = require.context("../", true, /\.routes.js$/);
const routesModules = routesFiles.keys().reduce((routesModules, routePath) => {
  const value = routesFiles(routePath);
  routesModules.push(value.default);
  return routesModules;
}, []);

const routes = [
  //modules routes
  ...routesModules,
  //fallback route
  {
    path: "*",
    component: NotFound,
    meta: { title: "صفحة غير موجودة" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
  },
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

//change the title of the browser tab according to route's meta title
const DEFAULT_TITLE = "البركة | ناشري فرص عمل ";
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  Vue.nextTick(() => {
    if (to.meta.title) document.title = to.meta.title;
    else document.title = DEFAULT_TITLE;
  });
});

//routes that need an authenticated or authorized user
router.beforeEach(async (to, from, next) => {
  if (!["login", "register", "verify_email","reset_password"].includes(to.name)) {
    if (localStorage.getItem("token") != undefined) {
      next();
    } else {
      next({ name: "login" });
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
