<template>
  <BaseTableItemAction
    v-if="item.status == 'pending'"
    v-on="$listeners"
    v-bind="$attrs"
    icon="mdi-pencil"
    actionName="تعديل"
    :to="'/job-opportunities/' + item.id + '/edit'"
  >
    <slot></slot>
  </BaseTableItemAction>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  methods: {
  },
};
</script>
