<template>
    <v-select
          v-model="selectedFilter"
          item-value="value"
          class="selection-width"
          :items="statusOptions"
          menu-props="auto"
          label="فلترة حالة فرص العمل"
          single-line
          hide-details
          prepend-inner-icon="mdi-filter"
          solo
          dense
          dark
          clearable
          background-color="accent"
          v-on="$listeners"
          v-bind="$attrs"
          @change="setFilterState()"
        >
        <template v-slot:item="{ item }">
          {{ item.label }}
        </template>
        <template v-slot:selection="{ item }">
         {{ item.label }}
        </template>
        </v-select>
</template>

<script>
import { mapActions } from 'pinia';
import {useJobOpportunitiesStore} from '../store/JobOpportunitiesStore';
import jobStatus from './statics/JobStatusEnum'
export default{
    data(){
        return{
            statusOptions:jobStatus,
            selectedFilter:null
        }
    },
    methods:{
        ...mapActions(useJobOpportunitiesStore,['setFilter']),
        setFilterState(){
            this.setFilter(this.selectedFilter);
        }
    }
}
</script>

<style scoped>
.selection-width{
    max-width: 200px;
}
</style>