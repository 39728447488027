<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col cols="11">
          <HomeBreadcrumbs />
          <HomeStatistics />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HomeBreadcrumbs from "../components/HomeBreadcrumbs.vue";
import HomeStatistics from "../components/HomeStatistics.vue";

export default {
  components: { HomeBreadcrumbs, HomeStatistics },
};
</script>

<style></style>
