<template>
  <BaseTableItemAction
    :icon="item.rate == null ? 'mdi-star-outline' : 'mdi-star'"
    actionName="تقييم المتقدم"
    @click="openRatingDialog()"
  >
    <RatingDialog
      :showCondition="showRatingDialog"
      :item="item"
      @closed="closeRatingDialog"
    />
    <slot></slot>
  </BaseTableItemAction>
</template>

<script>
import RatingDialog from "./RatingDialog.vue";

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      showRatingDialog: false,
    };
  },
  methods: {
    openRatingDialog() {
      this.showRatingDialog = true;
    },
    closeRatingDialog() {
      this.showRatingDialog = false;
    },
  },
  components: { RatingDialog },
};
</script>
