import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import JobApplicantsService from "../services/JobApplicantsService";
import JobApplicantKeyTransformation from "../models/JobApplicantKeyTransformation";
import PaginatedJobApplicantsCollection from "../models/PaginatedJobApplicantsCollection";

export const useJobApplicantsStore = defineStore("JobApplicantsStore", {
  state: () => ({
    paginatedApplicants: new PaginatedJobApplicantsCollection(),
    jobId: null,
    applicantsTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
    isOrganizationGraduate: null,
  }),
  getters: {
    getFilterSearchSortPageParameters() {
      var sortObj =
        this.applicantsTableOptions.sortBy.length != 0
          ? {
              sort_by:
                JobApplicantKeyTransformation[
                  this.applicantsTableOptions.sortBy[0]
                ],
              sort_desc: this.applicantsTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        search_key: this.searchKey,
        page: this.applicantsTableOptions.page,
        items_per_page: this.applicantsTableOptions.itemsPerPage,
        ...sortObj,
        is_organization_graduate: this.isOrganizationGraduate,
      });
    },
  },
  actions: {
    setJobId(id) {
      this.jobId = id;
    },
    setFilterNFetch(organizationGraduateFilter) {
      this.isOrganizationGraduate = organizationGraduateFilter;
      this.resetPaginatedApplicants();
      this.fetchJobApplicants();
    },
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedApplicants();
      this.fetchJobApplicants();
    },
    setTableOptionsNFetch(options) {
      (this.applicantsTableOptions = options), this.fetchJobApplicants();
    },
    resetPaginatedApplicants() {
      this.paginatedApplicants = new PaginatedJobApplicantsCollection();
    },
    fetchJobApplicants() {
      return BaseStore.promiseHandler(
        () =>
          JobApplicantsService.getPaginatedJobApplicants(
            this.jobId,
            this.getFilterSearchSortPageParameters
          ),
        (data) => {
          this.paginatedApplicants = new PaginatedJobApplicantsCollection(data);
        }
      );
    },
    sendApplicantRate(resumeId, rateData) {
      return BaseStore.promiseHandler(
        () => JobApplicantsService.sendApplicantRate(resumeId, rateData),
        () => {
          this.updateApplicantRateValue(resumeId, rateData);
        }
      );
    },
    updateApplicantRateValue(resumeId, data) {
      this.paginatedApplicants.collection.map((applicant) => {
        if (applicant.resumeId == resumeId) {
          applicant.rate = data.rate;
          applicant.rateContent = data.rateContent;
        }
      });
    },
  },
});
