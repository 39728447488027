<template>
  <BaseJobForm
    :jobObject="job"
    formActionName="تعديل الفرصة"
    @jobSubmitted="(submittedJob) => sendEditedJobData(submittedJob)"
  />
</template>

<script>
import { mapActions } from "pinia";
import BaseJobForm from "./BaseJobForm.vue";
import { useJobOpportunityStore } from "../../store/JobOpportunityStore";

export default {
  props: {
    job: Object,
  },
  methods: {
    ...mapActions(useJobOpportunityStore,['sendJobEditRequest']),
    sendEditedJobData(submittedJob) {
      this.sendJobEditRequest(this.job.id,submittedJob)
        .then(() => {
          //redirect back
          this.$router.go(-1);
        })
        .catch();
    },
  },
  components: { BaseJobForm },
};
</script>

<style scoped></style>
