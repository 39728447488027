import { Notify } from "../notify/Notify";
import { BaseError } from "../base/BaseError";
import router from "@/router";

export class UnAuthenticatedError extends BaseError {
  message = "انتهت صلاحية جلستك، الرجاء تسجيل الدخول مرة أخرى";

  dispatchError() {
    this.forceLogout();
    Notify.showMessage("warning", this.message);
  }

  forceLogout() {
    localStorage.clear();
    router.push("/login");
  }
}
