<template>
  <v-img
    class="d-none d-md-flex offering-job"
    src="@/assets/illustrations/offering-job.png"
    contain
  ></v-img>
</template>

<script>
export default {};
</script>

<style scoped>
.offering-job {
  position: absolute;
  width: 300px;
  left: 25px;
  bottom: 25px;
  opacity: 0.2;
}
</style>
