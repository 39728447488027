<template>
  <v-row align="center">
    <v-col align="start" cols="12" sm="8">
      <v-row
        class="d-flex flex-row align-center justify-sm-start justify-center"
      >
        <ProfileHeadingPicture />
        <ProfileHeadingTitle />
      </v-row>
    </v-col>
    <v-col
      class="d-flex flex-column align-center align-sm-end"
      cols="12"
      sm="4"
    >
      <ProfileHeadingActions :formIsValid="formIsValid"/>
    </v-col>
  </v-row>
</template>

<script>
import ProfileHeadingPicture from "./ProfileHeadingPicture.vue";
import ProfileHeadingActions from "./ProfileHeadingActions.vue";
import ProfileHeadingTitle from "./ProfileHeadingTitle.vue";

export default {
  components: {
    ProfileHeadingPicture,
    ProfileHeadingTitle,
    ProfileHeadingActions,
  },
  props: {
    formIsValid: Function,
  },
};
</script>

<style></style>
