<template>
  <BaseDialog :showCondition="show" @closed="closeDialog()">
    <template v-slot:title>
      <p class="primary--text">طلب تمديد الفرصة</p></template
    >
    <template v-slot:body>
      <p> 
        يجب أن يمر طلب تمديد الفرصة على الإدارة لتقوم بالموافقة عليه، الرجاء
        إرسال سبب التمديد
      </p>
      <small class="secondary--text">
        إذا لم تجد السبب المناسب ضمن الخيارات قم بكتابة السبب ضمن الحقل
      </small>
      <v-form ref="form">
        <v-combobox
          class="mt-5"
          v-model="selectedRenewalReason"
          chips
          clearable
          :items="[...renewalReasons,selectedRenewalReason]"
          label="سبب التمديد"
          placeholder="سبب التمديد"
          persistent-placeholder
          outlined
          :rules="[requiredRule]"
        >
        </v-combobox>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn color="accent" rounded @click="sendJobRenewalData()"
        >إرسال الطلب</v-btn
      >
    </template>
  </BaseDialog>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";
import { mapActions } from "pinia";
import { useJobOpportunityStore } from "../../store/JobOpportunityStore";

export default {
  props: {
    showCondition: Boolean,
    item: Object,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  mixins: [FormValidationRulesMixin],
  data() {
    return {
      selectedRenewalReason: null,
      show: this.showCondition,
      renewalReasons: [
        "لم أجد موظف مناسب",
        "تم تأمين الموظف ولكن الموظف لم يكمل",
        "شاغر جديد",
      ],
    };
  },
  methods: {
    closeDialog() {
      this.$refs.form.reset();
      this.show = false;
      this.$emit("closed");
    },
    ...mapActions(useJobOpportunityStore, ["sendRenewalRequest"]),
    sendJobRenewalData() {
      if (this.$refs.form.validate()) {
        var reasonData = { reason: this.selectedRenewalReason };
        this.sendRenewalRequest(this.item.id, reasonData)
          .then(() => {})
          .catch(() => {});
        this.closeDialog()
      }
    },
  },
};
</script>
