<template>
  <v-form ref="form">
    <v-container>
      <v-row justify="center">
        <v-col align="center" cols="9">
          <BaseTextField
            class="mt-3"
            v-model="email"
            label="البريد الالكتروني"
            placeholder="البريد الالكتروني"
            name="البريد الالكتروني"
            type="email"
            :rules="[requiredRule, emailRule]"
          >
          </BaseTextField>

          <v-btn
            class="accent rounded-xl px-8"
            @click="validateThenEmitCompleted()"
            >إرسال رمز التأكيد</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";

export default {
  mixins: [FormValidationRulesMixin],
  data() {
    return {
      email: "",
    };
  },
  methods: {
    validateThenEmitCompleted() {
      if (this.$refs.form.validate()) {
        this.$emit("completed", this.email);
      }
    },
  },
};
</script>

<style></style>
