import { render, staticRenderFns } from "./SideNav.vue?vue&type=template&id=92d474b0&scoped=true&"
import script from "./SideNav.vue?vue&type=script&lang=js&"
export * from "./SideNav.vue?vue&type=script&lang=js&"
import style0 from "./SideNav.vue?vue&type=style&index=0&id=92d474b0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92d474b0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VAvatar,VIcon,VImg,VListItem,VListItemContent,VNavigationDrawer,VTab,VTabs})
