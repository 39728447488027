<template>
  <div>
    <!-- Large Screens -->
    <img
      class="d-none d-lg-inline decoration left"
      src="@/assets/decorations/lg.png"
    />
    <img
      class="d-none d-lg-inline decoration right"
      src="@/assets/decorations/lg.png"
    />
    <!-- Medium Screens -->
    <img
      class="d-none d-md-inline d-lg-none decoration left"
      src="@/assets/decorations/md.png"
    />
    <img
      class="d-none d-md-inline d-lg-none decoration right"
      src="@/assets/decorations/md.png"
    />
    <!-- Small Screens -->
    <img
      class="d-none d-sm-inline d-md-none decoration left"
      src="@/assets/decorations/sm.png"
    />
    <img
      class="d-none d-sm-inline d-md-none decoration right"
      src="@/assets/decorations/sm.png"
    />
    <div
      :class="{
        'm-xl': $vuetify.breakpoint.xl,
        'm-lg': $vuetify.breakpoint.lg,
        'm-md': $vuetify.breakpoint.md,
        'm-sm': $vuetify.breakpoint.sm,
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseDecoratorWrapper",
};
</script>

<style scoped>
.decoration {
  position: fixed;
  width: auto;
  height: 100%;
  object-fit: contain;
}
.left {
  left: 0;
}
.right {
  right: 0;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.m-xl {
  margin: 0 275px;
}
.m-lg {
  margin: 0 200px;
}
.m-md {
  margin: 0 130px;
}
.m-sm {
  margin: 0 100px;
}
</style>
