<template>
  <v-row>
    <v-breadcrumbs :items="links">
      <template v-slot:divider>
        <v-icon>mdi-chevron-left</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :to="item.href"
          class="text-subtitle-2 crumb-item"
          :disabled="item.disabled"
          exact
        >
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </v-row>
</template>

<script>
import { mapState } from "pinia";
import { useProfileStore } from "../store/ProfileStore";

export default {
  computed: {
    ...mapState(useProfileStore, ["isEditable"]),
    links() {
      var links = [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
      ];
      if (!this.isEditable) {
        links.push({
          text: "الملف الشخصي",
          href: "/profile",
          disabled: true,
        });
      } else {
        links.push(
          {
            text: "الملف الشخصي",
            href: "/profile",
            disabled: false,
          },
          {
            text: "تعديل بيانات الشركة",
            href: "/profile",
            disabled: true,
          }
        );
      }
      return links;
    },
  },
};
</script>

<style></style>
