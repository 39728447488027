import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import JobKeysTransformations from "../models/JobKeysTransformations";
import JobOpportunitiesService from "../services/JobOpportunitiesService";
import PaginatedJobOpportunityCollection from "../models/PaginatedJobOpportunityCollection";

export const useJobOpportunitiesStore = defineStore("jobOpportunitiesStore", {
  state: () => ({
    paginatedJobs: new PaginatedJobOpportunityCollection(),
    jobTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
    selectedJobStatus: null,
  }),
  getters: {
    getFilterSearchPageParameters() {
      //prepare sort object in case sort applied (and transform sort cols name)
      var sortObj =
        this.jobTableOptions.sortBy.length != 0
          ? {
              sort_by: JobKeysTransformations[this.jobTableOptions.sortBy[0]],
              sort_desc: this.jobTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        page: this.jobTableOptions.page,
        ...sortObj,
        items_per_page: this.jobTableOptions.itemsPerPage,
        status: this.selectedJobStatus,
        search_key: this.searchKey,
      });
    },
  },
  actions: {
    setFilter(jobStatusFilter) {
      this.selectedJobStatus = jobStatusFilter;
      this.resetPaginatedJobs();
      this.fetchJobs();
    },
    setTableOptionsNFetch(options) {
      (this.jobTableOptions = options), this.fetchJobs();
    },
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedJobs();
      this.fetchJobs();
    },
    resetPaginatedJobs() {
      this.paginatedJobs = new PaginatedJobOpportunityCollection();
    },
    fetchJobs() {
      return BaseStore.promiseHandler(
        () =>
          JobOpportunitiesService.getPaginatedJobOpportunities(
            this.getFilterSearchPageParameters
          ),
        (data) => {
          this.paginatedJobs = new PaginatedJobOpportunityCollection(data);
        }
      );
    },
  },
});
