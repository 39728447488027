import { defineStore } from "pinia";

import HomeService from "../services/HomeService";
import BaseStore from "@/core/classes/base/BaseStore";

export const useHomeStore = defineStore("HomeStore", {
  state: () => ({
    statistics: {},
  }),
  actions: {
    getStatistics() {
      return BaseStore.promiseHandler(
        () => HomeService.getStatistics(),
        (data) => {
          this.statistics = data?.data[0];
        }
      );
    },
  },
});
