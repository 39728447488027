<template>
  <BaseTableItemAction
    v-if="item.applicantsCount > 0"
    v-on="$listeners"
    v-bind="$attrs"
    icon="mdi-account-group"
    actionName="عرض المتقدمين"
    :to="'/job-opportunities/' + item.id + '/applicants'"
  >
    <slot></slot>
  </BaseTableItemAction>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  methods: {},

};

</script>
