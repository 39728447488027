import Mapper from "@/core/classes/mapper/Mapper";
import Client from "@/core/classes/client/Client";
import BaseService from "@/core/classes/base/BaseService";
import {
  loginAPI,
  logoutAPI,
  registerAPI,
  verifyEmailAPI,
  resetPasswordAPI,
  resendVerificationCodeAPI,
  forgetPasswordAPI,
} from "./Auth.api";

export default class AuthService extends BaseService {
  static async register(data) {
    const res = await Client.post(registerAPI, Mapper.camelToUnderscore(data), {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return await Promise.resolve(res);
  }
  static async login(data) {
    const res = await Client.post(loginAPI, Mapper.camelToUnderscore(data));
    return await Promise.resolve(res);
  }
  static async verifyEmail(data) {
    const res = await Client.post(verifyEmailAPI, data);
    return await Promise.resolve(res);
  }
  static async resendVerificationCode() {
    const res = await Client.post(resendVerificationCodeAPI);
    return await Promise.resolve(res);
  }
  static async logout() {
    const res = await Client.delete(logoutAPI);
    return await Promise.resolve(res);
  }
  static async sendVerificationCodeForReset(data) {
    const res = await Client.post(forgetPasswordAPI, data);
    return await Promise.resolve(res);
  }
  static async resetPassword(data) {
    const res = await Client.post(resetPasswordAPI, data);
    return await Promise.resolve(res);
  }
}
