<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> إنشاء فرصة عمل</template>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2 class="primary--text">
            الرجاء تعبئة كل شاغر وظيفي مستقل على حدىً...
          </h2>
          <CreateJobForm />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import CreateJobForm from "../components/forms/CreateJobForm.vue";

export default {
  data() {
    return {
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "إدارة فرص العمل",
          href: "/job-opportunities",
          disabled: false,
        },
        {
          text: "إنشاء فرصة جديدة",
          disabled: true,
        },
      ],
    };
  },
  components: { CreateJobForm },
};
</script>
