<template>
  <BaseDialog :showCondition="show" @closed="closeDialog()">
    <template v-slot:title>
      <p class="primary--text">أعط رأيك بالمتقدم</p>
    </template>
    <template v-slot:body>
      <v-form ref="form">
        <v-textarea
          v-model="rateContent"
          :rules="[requiredRule]"
          label="محتوى الرأي"
          placeholder="ما هو رأيك بالمتقدم؟"
          persistent-placeholder
          filled
          outlined
          :readonly="readonly"
        ></v-textarea>
        <v-input :value="rate" :rules="[requiredRule]">
          <v-col align="center">
            <v-rating
              v-model="rate"
              hover
              length="5"
              size="45"
              :readonly="readonly"
            ></v-rating>
          </v-col>
        </v-input>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn
        v-if="!readonly"
        color="accent"
        rounded
        @click="validateThenSendRate()"
      >
        إرسال التقييم
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";

import { mapActions } from "pinia";
import { useJobApplicantsStore } from "../store/JobApplicantsStore";

export default {
  props: {
    showCondition: Boolean,
    item: Object,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  mixins: [FormValidationRulesMixin],
  data() {
    return {
      rate: this.item.rate,
      rateContent: this.item.rateContent,
      readonly: this.item.rate != null,
      show: this.showCondition,
    };
  },
  methods: {
    closeDialog() {
      if (!this.readonly) this.$refs.form.reset();
      this.show = false;
      this.$emit("closed");
    },
    ...mapActions(useJobApplicantsStore, ["sendApplicantRate"]),
    validateThenSendRate() {
      if (this.$refs.form.validate()) {
        var rateData = { rate: this.rate, rateContent: this.rateContent };
        this.sendApplicantRate(this.item.resumeId, rateData).then(()=>{
          this.readonly=true;
          this.closeDialog();
        });
      }
    },
  },
};
</script>
