import EditAccountInformationPage from "../pages/EditAccountInformationPage.vue";
import UserLayout from "@/core/layouts/UserLayout.vue";
import ProfilePage from "../pages/ProfilePage.vue";
import BaseFormPageWrapperVue from "@/core/base/wrappers/BaseFormPageWrapper.vue";

export default {
  path: "/profile",
  component: UserLayout,
  children: [
    {
      path: "/profile",
      component: BaseFormPageWrapperVue,
      children: [
        {
          path: "",
          name: "profile",
          component: ProfilePage,
          meta: { title: "الملف الشخصي" },
        },
      ],
    },
    {
      path: "/edit-account-information",
      component: BaseFormPageWrapperVue,
      children: [
        {
          path: "",
          name: "edit_account_information",
          component: EditAccountInformationPage,
          meta: { title: "الملف الشخصي" },
        },
      ],
    },
  ],
};
