<template>
  <v-col class="mt-5" cols="12" sm="6">
    <EditableTextField
      v-model="profile.about"
      :model="profile.about"
      :rules="[requiredRule]"
      icon="mdi-information"
      label="عن الشركة"
    />
    <EditableTextField
      v-model="profile.field"
      :model="profile.field"
      :rules="[requiredRule]"
      icon="mdi-domain"
      label="مجال العمل"
    />
    <EditableTextField
      v-model="profile.location"
      :model="profile.location"
      :rules="[requiredRule]"
      icon="mdi-map-marker"
      label="الموقع"
    />
    <EditableTextField
      v-model="profile.phoneNumber"
      :model="profile.phoneNumber"
      :rules="[requiredRule]"
      type="number"
      icon="mdi-phone"
      label="رقم الهاتف"
    />
    <EditableTextField
      v-if="!isEditable"
      v-model="profile.email"
      :model="profile.email"
      :rules="[requiredRule, emailRule]"
      type="email"
      icon="mdi-email"
      label="البريد الالكتروني"
    />
  </v-col>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";
import EditableTextField from "./ProfileBodyEditableTextField.vue";
import { EventBus } from "@/main";
import { useProfileStore } from "../store/ProfileStore";
import { mapWritableState,mapState } from "pinia";

export default {
  mixins: [FormValidationRulesMixin],
  components: { EditableTextField },
  computed: {
    ...mapWritableState(useProfileStore, ["profile", "ref"]),
    ...mapState(useProfileStore,["isEditable"]),
  },
  watch:{
    profile: {
      deep: true,
      handler() {
        EventBus.$emit("formChanged");
      },
    },
  },
  methods: {
    validate() {
      if(this.$refs.form.validate()){
        EventBus.$emit('formSubmitted');
      }
    },
  },
};
</script>

<style></style>
